import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import demo6 from "../../assets/demo/demo.webp";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiAboutCard from "../../components/cards/UiAboutCard";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiHomeAboutCard from "./components/UiHomeAboutCard";
//import UiHomeMobileInfo from "./components/UiHomeMobileInfo";
import UiHomeSpecialSlider from "./components/UiHomeSpecialSlider";
import UiHomeQuizButton from "./components/UiHomeQuizButton";

import UiModalProduct from "../../components/modals/UiModalProduct";
import UiFastBuyModal from "../../components/modals/UiFastBuyModal";

import {
  getStorageListByCat as getStorageListByCatAction,
  getSliderList as getSliderListAction,
} from "../../redux/modules/storage";
import {
  clearUserBasket as clearUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";
import { getMenuCategoryList as getMenuCategoryListAction } from "../../redux/modules/category";

import "../../styles/catalog.scss";

import { convertToList } from "../../utilities/storage";
import UiSliderReviews from "../../components/sliders/UiSliderReviews";
import UiHomeCategories from "./components/UiHomeCategories";
import UiPageText from "../../components/text/UiPageText";

function HomePage(props) {
  let { title, price } = useParams();
  const navigate = useNavigate();
  const [promoList, setPromoList] = useState([
    {
      image: demo6,
      title: "",
      text: "",
      link: "/",
    },
  ]);
  const [catalogListOnline, setCatalogListOnline] = useState([
    { loader: true, price: 1, title: "1" },
    { loader: true, price: 1, title: "1" },
    { loader: true, price: 1, title: "1" },
    { loader: true, price: 1, title: "1" },
  ]);

  const [homeCatalog, setHomeCatalog] = useState([
    /*
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    },
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    },
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    }
    */
  ]);

  const [modalProductOpen, setModalProductOpen] = useState(false);
  const [fastBuy, setFastBuy] = useState(false);
  const [useScroll, setUseScroll] = useState(false);
  const [userPriceScroll, setUserPriceScroll] = useState(true);

  function _loadMainCat() {
    props.getStorageListByCat(18).then((list0) => {
      setCatalogListOnline(convertToList(list0));
      setUseScroll(true);
    });
  }

  async function _load() {
    const slider = await props.getSliderList();
    setPromoList(slider);
    const data = await props.getMenuCategoryList();
    setHomeCatalog(data.reverse());

    _loadMainCat();
    /*
    window.addEventListener('scroll', (event) => {
      if(useScroll == false){
        _loadMainCat()
      }
    }, { once: true });
    */
  }

  useEffect(() => {
    _load();
  }, []);

  useEffect(() => {
    if (title) setModalProductOpen(true);
  }, [title]);

  useEffect(() => {
    if (price && userPriceScroll) {
      _loadMainCat();
      if (document.getElementById("sorted-price")) {
        document.getElementById("sorted-price").scrollIntoView();
        setUserPriceScroll(false);
      }
    }
  }, [price, document.getElementById("sorted-price")]);

  let homeList = homeCatalog.map((item, index) => {
    if (item.catalog.length > 0) {
      return (
        <UiCatalog
          key={index}
          title={item.data.title}
          subtitle={item.data.full_title}
          sortPrice={true}
          id={"sorted-price1"}
          filters={true}
          priceFilter={price}
          list={convertToList(item.catalog)}
          minimized={true}
          scroll={false}
          white={true}
          onFastClick={(fastItem) => {
            props.clearUserBasket();
            fastItem.count = 1;
            props.addUserBasket(fastItem);
            navigate(`/order`);
          }}
        />
      );
    }
  });

  return (
    <PageContainer
      page="home"
      pageTitle="Flowery - современная флористика, доставка цветов в Южно-Сахалинске"
      pageDescription="Flowery - цветочный магазин и доставка цветов в Южно-Сахалинске: быстрая доставка, свежие цветы, большой выбор цветов и букетов"
    >
      <section className="home-title-wrap">
        <Container>
          <div className="home-title">
            <div className="home-title-sliders">
              <UiHomeSpecialSlider slideList={promoList} />
              <UiHomeQuizButton />
            </div>
          </div>
        </Container>
      </section>

      <UiHomeCategories />

      {homeList}

      {useScroll && catalogListOnline.length > 0 && (
        <UiCatalog
          title="Наши эксклюзивные букеты"
          subtitle="Сделай неповторимый и оригинальный подарок"
          id={"sorted-price"}
          filters={true}
          sortPrice={true}
          list={catalogListOnline}
          minimized={true}
          priceFilter={price}
          onChangeFilter={(val) => {
            navigate(`/filter/${val}`);
          }}
          onFastClick={(val) => {
            navigate(`/filter/${val}`);
          }}
        />
      )}
      <section className="home-gifts-special-wrap">
        <Container>
          <div className="home-gifts-special"></div>
        </Container>
      </section>
      <section className="home-about-wrap">
        <Container>
          <UiAboutCard center />
          <UiHomeAboutCard />
        </Container>
      </section>
      <section className="home-reviews-wrap">
        <Container>
          <UiSliderReviews />
        </Container>
      </section>
      <UiPageText page="home" />
      {/*
      <UiHomeMobileInfo />
       */}
      <UiModalProduct
        modalOpen={modalProductOpen}
        modalClose={() => {
          setModalProductOpen(false);
          navigate(`/`);
        }}
      />
      <UiFastBuyModal
        modalOpen={fastBuy}
        modalClose={() => {
          setFastBuy(false);
        }}
      />
    </PageContainer>
  );
}

export default connect(({ storage }) => ({ storage }), {
  getSliderList: getSliderListAction,
  getStorageListByCat: getStorageListByCatAction,
  getMenuCategoryList: getMenuCategoryListAction,
  addUserBasket: addUserBasketAction,
  clearUserBasket: clearUserBasketAction,
})(HomePage);
